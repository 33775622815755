import { Heading } from '@kiwicom/orbit-components'
import Col from 'lib/components/Col'
import Gap from 'lib/components/Gap'
import BetterLink from 'src/components/BetterLink'
import { Page, PageContent } from 'src/components/Page'
import SEO from 'src/components/SEO'

export default function Custom404() {
  const title = 'Strana nenalezena'
  return (
    <Page bg="cloudLight">
      <SEO title={title} />

      <PageContent>
        <Col alignItems="center">
          <Gap gap="80px" />
          <Heading type="display">{title}</Heading>
          <Gap gap="32px" />

          <BetterLink href={BetterLink.HOMEPAGE}>Zpět na domovskou stránku</BetterLink>
        </Col>

        <Gap gap="80px" />
      </PageContent>
    </Page>
  )
}
